.about-container {
    width: 75%;
    display: flex;
    flex-wrap: wrap;
    margin: 0 auto;
}

.about-gallery {
    width: 50%;
}

.about-text {
    background-color: #ffffff;
    width: 50%;
    padding: 50px 45px;
    position: relative;
    h2.section-title {
        margin-top: 0;
        position: relative;
        z-index: 2;
    }
    &.rose-background {
        &:after {
            z-index: 0 !important;
        }
    }
}

@media (max-width: 745px) {
    .about-container {
        width: 100%;
    }
    .about-text, .about-gallery {
        width: 50%;
    }
}

@media (max-width: 480px) {
    .about-container {
        width: 100%;
    }
    .about-text, .about-gallery {
        width: 100%;
    }
    .about-text {
        padding: 40px 30px;
    }
}