/* Generated by Fontie <http://fontie.pixelsvsbytes.com> */

@font-face {
    font-family:'Playfair Display';
    src: url('Playfair Display Bold.eot');
	src: url('Playfair Display Bold.eot?#iefix') format('embedded-opentype'),
		url('Playfair Display Bold.woff2') format('woff2'),
		url('Playfair Display Bold.woff') format('woff'),
		url('Playfair Display Bold.svg#Playfair Display Bold') format('svg');
    font-weight: 700;
    font-style: normal;
    font-stretch: normal;
    unicode-range: U+0020-2212;
}

@font-face {
    font-family:'Playfair Display';
    src: url('Playfair Display Regular.eot');
	src: url('Playfair Display Regular.eot?#iefix') format('embedded-opentype'),
		url('Playfair Display Regular.woff2') format('woff2'),
		url('Playfair Display Regular.woff') format('woff'),
		url('Playfair Display Regular.svg#Playfair Display Regular') format('svg');
    font-weight: 400;
    font-style: normal;
    font-stretch: normal;
    unicode-range: U+0020-2212;
}