.service-wrapper {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -15px;
    .service-box {
        width: 50%;
        flex: 0 0 50%;
        display: flex;
        padding: 0 15px;
        a {
            color: #000000;
        }
        &:nth-child(2) {
            justify-content: flex-end;;
        }
        &:nth-child(2n-1) {
            figure {
                margin-top: 100px;
            }
        }
        .service-image {
            width: 80%;
            img {
                box-shadow: 0 0 25px rgba(0,0,0,0.25);
            }
        }
        figure {
            figcaption {
                text-align: right;
                top: -50%;
                transform: translateY(-50%);
                width: 80%;
                float: right;
            }
        }
    }
}

@media (min-width:980px) and (max-width:1199px) {
    .service-wrapper {
        .service-box {
            figure {
                figcaption {
                    width: 90%;
                }
            }
        }
    }
}

@media (min-width:768px) and (max-width:979px) {
    .service-wrapper {
        .service-box {
            figure {
                figcaption {
                    width: 90%;
                }
            }
        }
    }
}

@media (max-width: 979px) and (min-width: 768px) {
    .service-wrapper {
        .service-box {
            .service-image {
                width: 80%;
            }
            .service-caption {
                font-size: 2.2em;
            }
        }
    }
}

@media (min-width:746px) and (max-width:767px) {
    .service-wrapper {
        .service-box {
            .service-caption {
                font-size: 2em;
            }
        }
    }
}

@media (max-width: 745px) {
    .service-wrapper {
        .service-box {
            width: 50%;
            flex: 0 0 50%;
            .service-image {
                width: 100%;
            }
            .service-caption {
                font-size: 2em;
            }
            &:nth-child(2n-1) {
                figure {
                    margin-top: 0;
                }
            }
            figure {
                figcaption {
                    width: 100%;
                }
            }
        }
    }
}

@media (max-width: 480px) {
    .service-wrapper {
        .service-box {
            width: 100%;
            flex: 0 0 100%;
        }
    }
}