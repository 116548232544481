body {
    font-family: "Open Sans Regular", sans-serif;
    background-color: #e5e5e5;
    font-size: 14px;
}

* {
    box-sizing: border-box;
}

figure {
    margin: 0;
    padding: 0;
}

a {
    text-decoration: none;
    color: #aa947d;
    &:hover {
        text-decoration: underline;
    }
}

.img-responsive {
    display: block;
    width: 100%;
    max-width: 100%;
}

.text-center {
    text-align: center;
}

.text-justify {
    text-align: justify;
}

h1, h2, h3, h4, h5, h6 {
    font-family: "Playfair Display", sans-serif;
    font-weight: normal;
    line-height: 1.3em;
    &.service-caption {
        font-family: "Open Sans ExtraBold", arial;
        font-weight: 800;
        font-size: 3em;
        text-transform: uppercase;
        margin: 0;
    }
}

h2 {
    font-size: 2.714em;
}

.section-title {
    margin-top: 0;
    margin-bottom: 50px;
}

input, textarea {
    border-top: 0 none;
    border-left: 0 none;
    border-right: 0 none;
    border-bottom: 1px solid rgba(0,0,0,.1);
    padding: 10px 0;
    width: 100%;
    max-width: 100%;
    margin-top: 5px;
    margin-bottom: 5px;
    resize: none;
}

.btn {
    display: inline-block;
    cursor: pointer;
    border: 0 none;
    padding: .75rem 1rem;
    text-align: center;
    &:hover {
        text-decoration: none;
    }
    svg {
        max-width: 30px;
        margin-left: .5rem;
        width: 100%;
    }
    &.standard {
        display: flex;
        flex-wrap: wrap;
        align-content: center;
        justify-content: center;
        background-color: #d4b99c;
        font-family: "Playfair Display", arial;
        color: #fff;
        font-size: 1.143em;
        line-height: 30px;
        -webkit-appearance: none;
        border-radius: 0;
        transition: .2s ease-in-out;
        &:hover {
            background-color: #b69f86;
        }
    }
}

.rose-background {
    &:after {
        background: url(../images/flowers.svg) no-repeat;
        position: absolute;
        content: "";
        display: block;
        height: 253px;
        width: 135px;
        left: 0;
        top: 0;
        z-index: -1;
        opacity: 0.05;
    }
}

#content {
    &.section {
        padding: 60px 0;
    }
    p {
        font-size: 1rem;
        line-height: 2.4em;
    }
}

@media (max-width: 1199px) and (min-width: 980px) {
    .section-title {
        font-size: 2em;
    }
}

@media (max-width: 979px) and (min-width: 768px) {
    .section-title {
        font-size: 1.9em;
    }
}

@media (max-width: 767px) and (min-width: 746px) {
    .section-title {
        font-size: 1.9em;
    }
}

@media (max-width: 745px) {
    .section-title {
        font-size: 1.7em;
    }
}

@media (max-width: 480px) {
    .section-title {
        font-size: 2.2em;
    }
}