#logo {
    padding: 40px 30px;
    position: fixed;
    left: 0;
    top: 0;
    z-index: 9999;
    opacity: 0;
    transition: .2s ease-in-out;
    transition-delay: .6s;
    transform: scale(0);
    &.visible {
        opacity: 1;
        transform: scale(1);
    }
    .shadow {
        -webkit-filter: drop-shadow( 1px 1px 4px rgba(0,0,0,0.2) );
        -moz-filter: drop-shadow( 1px 1px 4px rgba(0,0,0,0.2) );
        -o-filter: drop-shadow( 1px 1px 4px rgba(0,0,0,0.2) );
        filter: drop-shadow( 1px 1px 4px rgba(0,0,0,0.2) );
        display: block;
    }
}

.hamburger {
    position: fixed;
    right: 0;
    top: 0;
    z-index: 9999;
    padding: 40px 30px;
    margin-top: 12px;
    display: block;
    opacity: 0;
    transition: .2s ease-in-out;
    transition-delay: 1.1s;
    transform: scale(0);
    &.visible {
        opacity: 1;
        transform: scale(1);
    }
    &.hidden {
        display: none;
    }
    .hamburger-inner, .hamburger-inner:before, .hamburger-inner:after {
        background-color: #fff !important;
        border-radius: 0;
        box-shadow: 1px 1px 4px rgba(0,0,0,0.2);
        width: 48px;
    }
    .hamburger-box {
        width: 48px;
    }
}

#intro {
    background-color: #222222;
    position: relative;
    overflow: hidden;
    background: url(../images/julia-cosmetics-background-2.jpg) no-repeat center center / cover;
    &.fullscreen {
        height: 100vh;
        width: 100%;
    }
    &.normal {
        height: 540px;
    }
    .address {
        position: absolute;
        left: 0;
        right: 0;
        bottom: 30px;
        margin: 0 auto;
        @media screen and (min-width: 520px) {
            right: 60px;
            bottom: 30px;
        }
        h2 {
            color: #ffffff;
            text-align: center;
            font-size: 1.75rem;
            @media screen and (min-width: 520px) {
                text-align: right;
            }
        }
    }
    .caption-wrapper {
        position: absolute;
        display: block;
        width: 50%;
        top: 50%;
        transform: translateY(-50%);
        left: 0;
        right: 0;
        margin-left: auto;
        margin-right: auto;
        text-align: right;
        padding-left: 15px;
        padding-right: 15px;
        .caption {
            position: relative;
            width: 65%;
            float: left;
            &:before {
                background: url(../images/intro-square.svg) no-repeat center center / cover;
                position: absolute;
                content: "";
                display: block;
                height: 420px;
                width: 420px;
                left: -25%;
                top: 50%;
                transform: translateY(-50%);
                z-index: -1;
            }
            .page-title {
                font-size: 5.143em;
                margin: 0;
                color: #fff;
                transform: translateX(-100px);
                transition: .5s ease-in-out;
                opacity: 0;
                line-height: 1em;
                position: absolute;
                bottom: 0;
                &.visible {
                    transform: translateX(0%) translateY(100%);
                    opacity: 1;
                }
            }
        }
    }
}

#side-menu {
    background-color: #d4b99c;
    position: fixed;
    z-index: 999;
    height: 100%;
    width: 320px;
    right: 0;
    top: 0;
    transform: translateX(100%);
    transition: 0.2s ease-in-out;
    &.is-active {
        transform: translateX(0);
        transition: 0.1s ease-in-out;
    }
}

#menu ul {
    position: absolute;
    margin-top: 120px;
    width: 100%;
    padding: 0;
    li {
        display: block;
        width: 100%;
        text-align: center;
        position: relative;
        a {
            display: block;
            padding-top: 10px;
            padding-bottom: 10px;
            color: #fff;
            font-family: "Playfair Display", sans-serif;
            font-size: 2em;
            text-decoration: none;
            &:hover {
                text-decoration: underline;
            }
        }
    }
}

@media (max-width: 1610px) and (min-width: 1200px) {
    #intro {
        .caption-wrapper {
            .caption {
                .page-title {
                    font-size: 4em;
                }
            }
        }
    }
}

@media (max-width: 1199px) and (min-width: 980px) {
    #intro {
        .caption-wrapper {
            .caption {
                width: 100%;
                .page-title {
                    font-size: 4em;
                }
            }
        }
    }
}

@media (max-width: 979px) and (min-width: 768px) {
    #intro {
        .caption-wrapper {
            .caption {
                width: 90%;
                &:before {
                    height: 300px;
                    width: 300px;
                    left: -150px;
                }
                .page-title {
                    font-size: 2.6em;
                }
            }
        }
    }
}

@media (max-width: 767px) and (min-width: 746px) {
    #intro {
        .caption-wrapper {
            .caption {
                width: 90%;
                &:before {
                    height: 300px;
                    width: 300px;
                    left: -150px;
                }
                .page-title {
                    font-size: 2.6em;
                }
            }
        }
    }
}

@media (max-width: 745px) {
    #intro {
        .caption-wrapper {
            width: 100%;
            .caption {
                &:before {
                    height: 200px;
                    width: 200px;
                    left: 0;
                }
                .page-title {
                    font-size: 2.5em;
                }
            }
        }
    }
}

@media (max-width: 480px) {
    #intro {
        .caption-wrapper {
            width: 100%;
            .caption {
                width: 75%;
                &:before {
                    height: 200px;
                    width: 200px;
                    left: 0;
                }
                .page-title {
                    font-size: 2.2em;
                }
            }
        }
    }
}