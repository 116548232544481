.container {
    width: 100%;
    max-width: 1170px;
    position: relative;
    margin: 0 auto;
    padding-left: 15px;
    padding-right: 15px;
}

.section {
    position: relative;
    padding-top: 150px;
    padding-bottom: 150px;
}

@media (max-width: 745px) {
    .section {
        padding: 40px 0;
    }
}