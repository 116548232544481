#contact {
    position: relative;
    margin-top: 100px;
    .form-container {
        background-color: #fff;
        position: relative;
        width: 400px;
        margin: 0 auto;
        padding: 30px 40px;
        &:before {
            background-color: transparent;
            position: absolute;
            z-index: -1;
            height: 100%;
            width: 100%;
            border: 6px solid #d4b99c;
            content: "";
            display: block;
            top: 20px;
            left: 20px;
        }
        .widget-title {
            font-size: 2em;
            text-align: center;
            padding: 0;
        }
    }
}

input {
    &[name="subject"] {
        display: none;
    }
}

@media (max-width: 480px) {
    #contact {
        .form-container {
            width: calc(100% - 30px);
            &:before {
                display: none;
            }
        }
    }
}