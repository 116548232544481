/* Generated by Fontie <http://fontie.pixelsvsbytes.com> */

@font-face {
    font-family:'Open Sans ExtraBold';
    src: url('Open Sans ExtraBold.eot');
	src: url('Open Sans ExtraBold.eot?#iefix') format('embedded-opentype'),
		url('Open Sans ExtraBold.woff2') format('woff2'),
		url('Open Sans ExtraBold.woff') format('woff'),
		url('Open Sans ExtraBold.svg#Open Sans ExtraBold') format('svg');
    font-weight: 800;
    font-style: normal;
    font-stretch: normal;
    unicode-range: U+0020-2074;
}