/* Generated by Fontie <http://fontie.pixelsvsbytes.com> */

@font-face {
    font-family:'Open Sans';
    src: url('Open Sans Regular.eot');
	src: url('Open Sans Regular.eot?#iefix') format('embedded-opentype'),
		url('Open Sans Regular.woff2') format('woff2'),
		url('Open Sans Regular.woff') format('woff'),
		url('Open Sans Regular.svg#Open Sans Regular') format('svg');
    font-weight: 400;
    font-style: normal;
    font-stretch: normal;
    unicode-range: U+0020-2212;
}