.pricing-wrapper {
    margin: 0 -15px;
    .pricing-item {
        padding: 0 15px;
        width: 33.3333%;
        margin-bottom: 15px;
        .gallery-text {
            position: relative;
            background-color: #d4b99c;
            color: #fff;
            z-index: 2;
            padding: 50px 45px;
            margin-bottom: 15px;
            width: 100%;
        }
    }
}

span {
    .sub-title {
        color: rgba(255,255,255,0.8);
        font-style: italic;
    }
}

table {
    width: 100%;
    tr {
        &:last-child {
            td {
                border-bottom: 0 none;
            }
        }
        td {
            border-bottom: 1px dotted #eee;
            &:first-child {
                font-size: 1.143em;
                text-align: left;
            }
            &:nth-child(2) {
                font-size: 1.571em;
                font-family: "Open Sans ExtraBold", sans-serif;
                font-weight: bold;
                text-align: right;
                vertical-align: bottom;
                span {
                    font-family: "Open Sans Regular", sans-serif;
                    font-weight: 200;
                    font-size: 14px;
                }
            }
        }
    }
}

@media (min-width:768px) and (max-width:979px) {
    .pricing-wrapper {
        .pricing-item {
            width: 50%;
        }
    }
}

@media (max-width: 768px) {
    .pricing-wrapper {
        .pricing-item {
            width: 50%;
        }
    }
}

@media (max-width: 745px) {
    .pricing-wrapper {
        .pricing-item {
            width: 50%;
        }
    }
}

@media (max-width:620px) {
    .pricing-wrapper {
        .pricing-item {
            width: 100%;
        }
    }
}

@media (max-width: 480px) {
    .pricing-wrapper {
        .pricing-item {
            width: 100%;
        }
    }
}