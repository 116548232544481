#gallery {
    .row {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        margin: 0 -15px;
        .thumbnail {
            img {
                box-shadow: 0 0 25px rgba(0,0,0,0.25);
                padding: 5px;
                border: 1px solid #ffffff;
                margin-left: 15px;
                margin-right: 15px;
                margin-bottom: 30px;
                cursor: pointer;
            }
        }
    }
}

.react-fancybox {
    .box {
        z-index: 9999;
    }
}